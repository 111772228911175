import { useMemo } from 'react';
import useSWR from 'swr';

import { type DtoPersonality } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';

export function usePersonalities() {
  return useSWR(
    '/training/personalities',
    async () => {
      const resp = await apiService.training.getPersonalities();
      return resp.data.personalities;
    },
    {
      dedupingInterval: 30000,
      keepPreviousData: true,
      shouldRetryOnError: false,
    }
  );
}

export function findDefaultPersonality(
  personalities: DtoPersonality[] | null | undefined
) {
  return personalities?.find((p) => p.publiclyListed);
}

export function usePersonality(
  personalityId: string | null | undefined,
  options?: {
    // when true, will fallback to the first publicly listed personality.
    fallbackToDefault?: boolean;
    // when provided, will fallback to the personality with this id, if it exists.
    // this option takes precedence over fallbackToDefault.
    fallbackToId?: string;
  }
) {
  const { data: personalities } = usePersonalities();
  return useMemo(() => {
    const personality = personalities?.find((p) => p.id === personalityId);
    if (personality) return personality;
    if (options?.fallbackToId) {
      const personality = personalities?.find(
        (p) => p.id === options.fallbackToId
      );
      if (personality) return personality;
    }
    if (options?.fallbackToDefault)
      return findDefaultPersonality(personalities);
    return null;
  }, [
    options?.fallbackToDefault,
    options?.fallbackToId,
    personalities,
    personalityId,
  ]);
}
